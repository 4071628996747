import React from "react";

import {CrudFilters, useNavigation} from "@pankod/refine-core";

import {
    useDataGrid,
    csCZ,
    GridColumns,
    List,
    Stack,
    ShowButton,
    DeleteButton,
    Typography,
    Button,
    Icon, CreateButton,
} from "@pankod/refine-mui";

import {format} from "date-fns";

import config from "../../config/config.local";

import {DataGridPro} from "@mui/x-data-grid-pro";

import {INasetriEntity} from "../../interfaces";

import {ListFilter} from "./listFilter";
import {Man, Woman} from "@mui/icons-material";
import { FormNotifications } from "../../helpers/formNotifications";

export const NasetriList: React.FC = () => {
    const { show } = useNavigation();
    const columns = React.useMemo<GridColumns<INasetriEntity>>(
        () => [
            {
                field: "nasetriPk",
                headerName: "EČ",
                type: "number",
                width: 80,
                headerAlign: "left",
                align: "left",
                sortable: false,
            },
            {
                field: "casZmeny",
                headerName: "Poslední změna",
                width: 150,
                type: 'dateTime',
                valueFormatter: ((value) => {
                    return value.value ? format(new Date(value.value), 'dd.MM.yyyy HH:mm') : "---";
                })
            },
            {
                field: "Klient",
                renderCell: function render({row}) {
                    let PohlaviIcon = (row?.Klient ? row?.Klient.pohlavi === 'MUZ' ? <Man sx={{color: 'blue', opacity: 0.7}}/> : row?.Klient.pohlavi === 'ZENA' ? <Woman sx={{color: 'red', opacity: 0.7}}/> : '' : <></>);
                    return row?.Klient ?
                        <>{PohlaviIcon} {row?.Klient.prijmeni}, {row?.Klient.krestni} ({row?.Klient.rokNarozeni})</>:
                        '---';
                },
                headerName: "Klient",
                type: 'string',
                width: 180,
                flex: 1,
                sortable: false,
            },
            {
                field: "Partner",
                renderCell: function render({row}) {
                    let PohlaviIcon = (row?.Partner ? row?.Partner.pohlavi === 'MUZ' ? <Man sx={{color: 'blue', opacity: 0.7}}/> : row?.Partner.pohlavi === 'ZENA' ? <Woman sx={{color: 'red', opacity: 0.7}}/> : '' : <></>);
                    return row?.Partner ?
                        <>{PohlaviIcon} {row?.Partner.prijmeni}, {row?.Partner.krestni} ({row?.Partner.rokNarozeni})</>:
                        '---';
                },
                headerName: "Partner",
                type: 'string',
                width: 180,
                flex: 1,
                sortable: false,
            },
            {
                field: "actions",
                type: "actions",
                headerName: "Akce",
                renderCell: function render({row}) {
                    return (
                        <>
                            <Stack direction="row" spacing={1}>
                                <Button
                                    startIcon={<Icon>login</Icon>}
                                    variant="text"
                                    target="_blank"
                                    href={config.APP_API_URL + '/auth/nasetri/' + row.nasetriPk}
                                >
                                    Otevřít
                                </Button>
                            </Stack>
                            {
                                (!(
                                    (row?.Klient && row?.Klient.typExistence === 'REALNY')
                                    ||
                                    (row?.Partner && row?.Partner.typExistence === 'REALNY')
                                )) &&
                                <DeleteButton
                                  recordItemId={row.nasetriPk}
                                  confirmTitle={"Opravdu chcete smazat položku?"}
                                  confirmCancelText={"Zrušit"}
                                  confirmOkText={"Smazat!"}
                                  successNotification={(data: any) => ({
                                      type: 'success',
                                      message: FormNotifications.successMessage(data)
                                  })}
                                  errorNotification={(data: any) => ({
                                      type: 'error',
                                      message: FormNotifications.errorMessage(data)
                                  })}
                                >
                                  Smazat
                                </DeleteButton>
                            }
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                width: 200,
            },
        ],
        [show]
    );

    const {dataGridProps, setFilters, filters, tableQueryResult} = useDataGrid<INasetriEntity>({
        columns,
        initialSorter: [{field: "casZmeny", order: "desc"}],
    });

    function onListFilterChange(Filters: CrudFilters) {
        setFilters(Filters);
    }

    return (
        <List
            headerProps={{
                title: <Typography variant="h5">Nedávno otevřená našetři ({tableQueryResult.data?.total})</Typography>,
                action: (
                    <Stack direction="row" gap={1}>
                        <CreateButton children="Nové anonymní našetři" color="success" variant={"text"} />
                    </Stack>
                )
            }}
        >

            <ListFilter onFilterChange={onListFilterChange} syncedLocationFilters={filters} />

            <DataGridPro
                {...dataGridProps}
                autoHeight
                getRowId={(row) => row.nasetriPk}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                filterMode="server"
                onFilterModelChange={() => {}}
                disableColumnMenu={true}
                pagination={true}
                sx={{
                    m: 0,
                    fontSize: '12px',
                }}
            />
        </List>

    );
};


