import {AuthProvider} from "@pankod/refine-core";

import { AxiosInstance } from "axios";

import config from "config/config.local"

import {FormNotifications} from "../helpers/formNotifications";

export const TOKEN_KEY = "nase3-seller";

export const REQUIRED_ROLE = 'ROLE_SELLER'

export const authProvider = (apiUrl: string, axios: AxiosInstance): AuthProvider => {
    return {
        login: async ({email, password, dorySystemId}) => {
            await axios.post(config.APP_API_SIGN, {
                email: email,
                password: password,
                dorySystemId: dorySystemId,
            }).catch((reason) => {
                console.log(reason);
                return Promise.reject({
                    name: "Nelze se přihlásit !",
                    message: FormNotifications.errorMessage(reason),
                });
            }).then(async (response) => {
                await axios.get(config.APP_API_CHECK)
                    .then((response) => {
                        const povoleni = response.data.session.povoleni;
                        if (!povoleni.includes(REQUIRED_ROLE)) {
                            return Promise.reject()
                        }
                        localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data));
                        return Promise.resolve();
                    })
                    .catch(() => {
                        localStorage.removeItem(TOKEN_KEY);
                        return Promise.reject({
                            name: "Chyba v přihlášení!",
                            message: "Nebylo možné ověřit uživatele.",
                        });
                    })
            });
        },
        logout: async () => {
            await axios.get(config.APP_API_LOGOUT, {
                maxRedirects: 1
            })
            .then((response) => {
                localStorage.removeItem(TOKEN_KEY);
                return Promise.resolve({
                    name: "Odhlášení",
                    message: "Proběhlo úspěšné odhlášení.",
                });
            })
            .catch(() => {
                return Promise.reject();
            })
        },
        checkError: ((error) => {
            if (error && error.response.status === 403) {
                return Promise.reject({
                    name: "Chyba v přihlášení!",
                    message: "Vyskytla se neočekávaná chyba.",
                });
            }
            if (error && error.response.status === 401) {
                return Promise.reject("/login");
            }
            if (error && error.response.status === 302) {
                return Promise.resolve();
            }
            return Promise.resolve();
        }),
        checkAuth: async () => {
            await axios.get(config.APP_API_CHECK)
                .then((response) => {
                    const povoleni = response.data.session.povoleni;
                    if (!povoleni.includes(REQUIRED_ROLE)) {
                        return Promise.reject()
                    }
                    localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data));
                    return Promise.resolve();
                })
                .catch((reason) => {
                    localStorage.removeItem(TOKEN_KEY);
                    return Promise.reject({
                        name: "Chyba v přihlášení!",
                        message: "Nebylo možné ověřit uživatele.",
                    });
                });
        },
        getPermissions: () => Promise.resolve(),
        getUserIdentity: async () => {
            const token = localStorage.getItem(TOKEN_KEY);
            if (!token) {
                return Promise.reject();
            }
            return Promise.resolve(JSON.parse(token));
        },
    }
};
