import * as React from "react";
import { useForm } from "@pankod/refine-react-hook-form";
import {
    Button,
    TextField,
    Box,
    Typography,
    Container,
    Card,
    CardContent,
} from "@mui/material";

import {
    BaseRecord,
    HttpError,
    useLogin,
    useTranslate,
} from "@pankod/refine-core";

import config from "../../config/config.local";
import {useImperativeHandle} from "react";

type ILoginForm = {
    email: string;
    password: string;
    dorySystemId: number;
    remember?: boolean;
};

export const CustomLoginPage: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<BaseRecord, HttpError, ILoginForm>();

    const { mutate: login, isLoading } = useLogin<ILoginForm>();

    return (
        <>
            <Box
                component="div"
                sx={{
                    background: `radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #CCCCCC 100%)`,
                    backgroundSize: "cover",
                }}
            >
                <Container
                    component="main"
                    maxWidth="xs"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100vh",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <img src={config.APP_BASENAME + "/logo.svg"} alt="Naše3 Logo" width="320" />
                        </div>
                        <Box mt={4}>
                            <Card>
                                <CardContent sx={{ paddingX: "32px" }}>
                                    <Typography
                                        component="p"
                                        align="center"
                                        sx={{
                                            fontWeight: "700",
                                            margin: "12px 0",
                                        }}
                                    >
                                        Přihlásit se je třeba přes DORY.
                                    </Typography>
                                    {/*
                                    <Box
                                        component="form"
                                        onSubmit={handleSubmit((data) => {
                                            login(data);
                                        })}
                                        gap="16px"
                                    >
                                        <TextField
                                            {...register("email", {
                                                required: true,
                                            })}
                                            id="email"
                                            margin="normal"
                                            size="small"
                                            fullWidth
                                            label="E-mail"
                                            name="email"
                                            type="email"
                                            autoFocus
                                        />
                                        <TextField
                                            {...register("password", {
                                                required: true,
                                            })}
                                            id="password"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            label="Heslo"
                                            name="password"
                                            type="password"
                                            placeholder="●●●●●●●●"
                                        />
                                        <TextField
                                            {...register("dorySystemId", {
                                                required: true
                                            })}
                                            id="dorySystemId"
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            label="DSID"
                                            name="dorySystemId"
                                            type="number"
                                            placeholder="Dory System ID"
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{
                                                my: "8px",
                                                color: "white",
                                            }}
                                            disabled={isLoading}
                                        >
                                            Přihlásit
                                        </Button>
                                    </Box>
                                    */}
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};
