import React from "react";

import {Refine} from "@pankod/refine-core";
import {
    notificationProvider,
    RefineSnackbarProvider,
    CssBaseline,
    GlobalStyles,
    Layout,
    ThemeProvider,
    LightTheme,
    ReadyPage,
    ErrorComponent, Icon,
} from "@pankod/refine-mui";

import {authProvider} from "./providers/authProvider";
import {dataProvider} from "./providers/dataProvider";
import {Sider} from "./layout/sider";
import {CustomLoginPage} from "./layout/login";
import {Header} from "./layout/header";
import {UpdatePasswordPage} from "./layout/updatePassword";
import {NasetriList} from "./pages/nasetri";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LicenseInfo} from '@mui/x-license-pro';
import csLocale from 'date-fns/locale/cs';
import config from "./config/config.local";
import axios from "axios";
import routerProvider, {BrowserRouterComponent} from "@pankod/refine-react-router-v6";
import {NasetriCreate} from "./pages/nasetri/create";
import {NasetriGrid} from "./ext/NasetriGrid";
import {IDataContextProvider} from "@pankod/refine-core/dist/contexts/data/IDataContext";
import {ConfigProps} from "./config/config";

BrowserRouterComponent.bind({
    initialRoute: "/nasetri",
})

const AppRouterComponent = () => <BrowserRouterComponent basename={config.APP_BASENAME}/>;

// Licenční klíč
LicenseInfo.setLicenseKey('5a5407e27c4cdfb57d1e821ae3192cc0Tz01MTkzNCxFPTE2OTY1NzYzMjAyMTksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
// Nastavení HTTP klienta

axios.defaults.withCredentials = true;
const axiosInstance = axios.create();

const overridedLightTheme = {
    ...LightTheme,
    palette: {
        ...LightTheme.palette,
        primary: {
            main: "#f67a0e",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#212121",
        },
    },
};

export function getRootDataAttributes() {
    const container = document.getElementById("root") as HTMLElement;
    let result = new Array<{name: string, value: any}>();
    for (let i = 0; i < container.attributes.length; i++) {
        const attribute = container.attributes.item(i);
        const name = attribute?.nodeName;
        if (name?.substring(0,4) === 'data') {
            result.push({name: name, value: attribute?.nodeValue})
        }
    }
    return result;
}

export function useDataProvider(module: ConfigProps): IDataContextProvider {
    switch (module.type) {
        case "ext":
            return dataProvider(config.APP_API_URL_EXT, axiosInstance);
        case "seller":
            return dataProvider(config.APP_API_URL, axiosInstance);
        default:
            return dataProvider(config.APP_API_URL, axiosInstance);
    }
}

function App() {

    const AppAttrs = getRootDataAttributes();

    const ExtAttr = AppAttrs.find((value) => value.name === 'data-ext');

    if (ExtAttr && ExtAttr.value === 'nasetri-grid') {
        const ExtToken = AppAttrs.find((value) => value.name ==='data-ext-token');
        return (<NasetriGrid jwtToken={ExtToken?.value}/>);
    }

    return (
        <ThemeProvider theme={overridedLightTheme}>
            <CssBaseline/>
            <GlobalStyles styles={{html: {WebkitFontSmoothing: "auto"}}}/>
            <RefineSnackbarProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={csLocale}>
                    <Refine
                        options={{
                            disableTelemetry: true,
                            syncWithLocation: true
                        }}
                        authProvider={authProvider(config.APP_API_URL, axiosInstance)}
                        dataProvider={dataProvider(config.APP_API_URL, axiosInstance)}
                        routerProvider={{
                            ...routerProvider,
                            RouterComponent: AppRouterComponent,
                            routes: [
                                {
                                    path: "/update-password/:token",
                                    element: <UpdatePasswordPage/>,
                                },
                            ],
                        }}
                        notificationProvider={notificationProvider}
                        Layout={Layout}
                        Header={Header}
                        Sider={Sider}
                        ReadyPage={ReadyPage}
                        LoginPage={CustomLoginPage}
                        catchAll={<ErrorComponent/>}
                        resources={[
                            {
                                name: "nasetri",
                                list: NasetriList,
                                create: NasetriCreate,
                                icon: <Icon>local_atm</Icon>,
                                options: {
                                    label: "Našetři"
                                },
                                canDelete: false,
                            },
                        ]}
                    />
                </LocalizationProvider>
            </RefineSnackbarProvider>
        </ThemeProvider>
    );
}
export default App;
