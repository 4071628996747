import React from "react";
import {
	Box,
	Create,
	Grid,
	ListButton, MenuItem,
	Stack,
	TextField,
	Typography
} from "@pankod/refine-mui";
import {SubmitHandler, useForm} from "@pankod/refine-react-hook-form";
import {INasetriEntity} from "../../interfaces";
import {FormNotifications} from "../../helpers/formNotifications";

export const NasetriCreate: React.FC = () => {

	const {
		refineCore: { formLoading, onFinish },
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<INasetriEntity>({
		refineCoreProps: {
			successNotification: (data: any) => ({
				type: 'success',
				message: FormNotifications.successMessage(data)
			}),
			errorNotification: (data: any) => ({
				type: 'error',
				message: FormNotifications.errorMessage(data)
			})
		}
	});

	const onSubmit: SubmitHandler<any> = values => onFinish(values);

	return (
		<Create
			isLoading={formLoading}
			breadcrumb={null}
			saveButtonProps={{
				children: "Uložit",
				onClick: handleSubmit(onSubmit),
				color: 'success'
			}}
			headerProps={{
				title: <Typography variant="h5">Nové anonymní našetři</Typography>,
				action: (
					<Stack direction="row" gap={1}>
						<ListButton children="Seznam" variant="text" color="info" />
					</Stack>
				)
			}}
		>
			<Box
				component="form"
				onSubmit={handleSubmit(onSubmit)}
				sx={{ display: "flex", flexDirection: "column" }}
				autoComplete="off"
			>
				<Stack gap={1}>
					<Grid container rowSpacing={2} columnSpacing={2}>
						<Grid item xs={12}>
							<TextField
								{...register("krestni", { required: "Křestní jméno je povinné." })}
								error={!!errors.krestni}
								required
								fullWidth
								type={'text'}
								label="Křestní"
							/>
							{errors.krestni && <small style={{color: "red"}}>Křestní jméno je povinné.</small>}
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...register("prijmeni", { required: "Příjmení je povinné." })}
								error={!!errors.prijmeni}
								required
								fullWidth
								type={'text'}
								label="Příjmení"
							/>
							{errors.prijmeni && <small style={{color: "red"}}>Přijmení je povinné.</small>}
						</Grid>
						<Grid item xs={6}>
							<TextField
								{...register("rokNarozeni", { required: "Rok narození je povinné." })}
								error={!!errors.rokNarozeni}
								required
								fullWidth
								defaultValue={new Date().getFullYear()}
								aria-valuemax={new Date().getFullYear()}
								type={'number'}
								label="Rok narození"
							/>
							{errors.rokNarozeni && <small style={{color: "red"}}>Rok narození je povinné.</small>}
						</Grid>
						<Grid item xs={6}>
							<TextField
								select
								fullWidth
								{...register('pohlavi', { required: 'Pohlaví je povinné.'})}
								error={!!errors.pohlavi}
								required
								label={'Pohlaví'}
								defaultValue={'MUZ'}
							>
								<MenuItem key={'MUZ'} value={'MUZ'}>Muž</MenuItem>
								<MenuItem key={'ZENA'} value={'ZENA'}>Žena</MenuItem>
							</TextField>
							{errors.pohlavi && <small style={{color: "red"}}>Pohlaví je povinné.</small>}
						</Grid>
					</Grid>
				</Stack>
			</Box>
		</Create>
	)
}
