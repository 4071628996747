export const FormNotifications = {
    successMessage: function(data: any) {
        return data?.data?.zprava
    },
    errorMessage: function(error: any) {
        const responseData = error?.response.data;
        const errors = error?.response.data.errors;
        let notificationErrors = [];
        if(errors) {
            for (const [field, fieldErrors] of Object.entries<Array<any>>(errors)) {
                if (fieldErrors.length > 0) {
                    notificationErrors.push(fieldErrors.join(', '))
                }
            }
        }
        if (responseData.zprava) {
            notificationErrors.push(responseData.zprava)
        }
        return notificationErrors.join(' ')
    },
};



// test 2

// export const useNase3Form = <
//     TData extends BaseRecord = BaseRecord,
//     TError extends HttpError = HttpError,
//     TVariables extends FieldValues = FieldValues,
//     TContext extends object = {}, >(
//         {
//             refineCoreProps,
//             warnWhenUnsavedChanges: warnWhenUnsavedChangesProp,
//             ...rest
//         }: UseFormProps<TData, TError, TVariables, TContext> = {}
// ): UseFormReturnType<TData, TError, TVariables, TContext> => {
//     const warnWhenUnsavedChanges =
//         warnWhenUnsavedChangesProp ?? warnWhenUnsavedChangesRefine;
//     return useForm({refineCoreProps, warnWhenUnsavedChanges, ...rest});
// };

// test 1

// export const useCustomForm extend useForm;
//
// export const useFormNotifications: UseFormProps = {
//     successNotification: (data?: unknown, values?: unknown, resource?: string) => ({
//         type: 'success',
//         message: ''
//     }),
//     errorNotification: (error?: unknown, values?: unknown, resource?: string) => {
//         const errors = data?.response.data.errors;
//         let notificationErrors = [];
//         if(errors) {
//             for (const [field, fieldErrors] of Object.entries<Array<any>>(errors)) {
//                 if (fieldErrors.length > 0) {
//                     notificationErrors.push(fieldErrors.join(', '))
//                 }
//             }
//         }
//         return {
//             type: 'error',
//             message: notificationErrors.join(' ')
//         };
//     }
// }
