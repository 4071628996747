import parentconfig from "config/config";

const config = {
    ...parentconfig,
    APP_API_URL:        'https://test-api.nase3.cz/v1/seller',
    APP_API_URL_EXT:    'https://test-api.nase3.cz/v1/ext',
    APP_API_SIGN:       'https://test-api.nase3.cz/v1/seller/security/login',
    APP_API_CHECK:      'https://test-api.nase3.cz/v1/seller/security/session',
    APP_API_LOGOUT:     'https://test-api.nase3.cz/v1/security/logout',
}

export default config;
