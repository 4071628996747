import {CrudFilters, CrudOperators, CrudSorting, DataProvider, LogicalFilter} from "@pankod/refine-core";
import restDataProvider from "@pankod/refine-simple-rest";
import { stringify } from "query-string";
import { AxiosInstance } from "axios";

const generateSort = (sort?: CrudSorting) => {
    let _defsort = ["casVytvoreni"]; // default sorting field
    let _deforder = ["desc"]; // default sorting
    let _sort = _defsort;
    let _order = _deforder;

    if (sort) {
        _sort = [];
        _order = [];

        sort.map((item) => {
            _sort.push(item.field);
            _order.push(item.order);
        });

        if (_sort.length === 0) {
            _sort = _defsort;
        }
        if (_order.length === 0) {
            _order = _deforder;
        }
    }

    return {
        _sort,
        _order,
    };
};

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "ne":
        case "gte":
        case "lte":
            return `_${operator}`;
        case "contains":
            return "_like";
    }

    return ""; // default "eq"
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};
    if (filters) {
        filters.map(( value, index, values ) => {
            let Filter = value as LogicalFilter;
            const mappedOperator = mapOperator(Filter.operator);
            queryFilters[`${Filter.field}${mappedOperator}`] = Filter.value;
        });
    }

    return queryFilters;
};

const camelize = (text: string) => {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return text.substr(0, 1).toLowerCase() + text.substr(1);
}

export const dataProvider = (apiUrl: string, axios: AxiosInstance): DataProvider => {
    return {
        ...restDataProvider(apiUrl, axios),
        update: async ({ resource, id, variables }) => {
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await axios.put(url, variables);

            return {
                data,
            };
        },
        getOne: async ({ resource, id }) => {
            let url;

            if (id) {
                url = `${apiUrl}/${resource}/${id}`;
            } else {
                url = `${apiUrl}/${resource}`;
            }

            const { data } = await axios.get(url);

            return {
                data,
            };
        },
        getMany: async ({ resource, ids, metaData }) => {
            const { data } = await axios.get(
                `${apiUrl}/${resource}/all`, {params: {
                    ids
                    }},
            );

            // fix camelized data key
            resource = camelize(resource);

            return {
                data: data[resource]
            };
        },
        getList: async ({ resource, hasPagination = true, pagination, filters, metaData, sort}) => {
            const url = `${apiUrl}/${resource}`;

            // pagination
            const current = pagination?.current || 1;
            const pageSize = pagination?.pageSize || 25;

            const { _sort, _order } = generateSort(sort);

            const queryFilters = generateFilter(filters);

            const query = {
                ...(hasPagination ? {
                    page: current,
                    limit: pageSize,
                } : {}),
                order: _sort.join(","),
                by: _order.join(",").toUpperCase(),
            };

            const { data } = await axios.get(
                `${url}?${stringify(query)}&${stringify(queryFilters)}`,
            );

            // fix latest path level
            const pathSeparatorIndex = resource.lastIndexOf('/');
            if (pathSeparatorIndex !== -1) {
                resource = resource.substring(pathSeparatorIndex+1);
            }

            // fix camelized data key
            resource = camelize(resource);

            return {
                data: data.data ?? data[resource],
                total: data.count,
            };
        },
    };
};
