import React, {MouseEvent, useState} from "react";
import {
	Button,
	FormControl,
	Grid,
	Input,
	InputLabel,
	Stack,
} from "@pankod/refine-mui";
import {Clear, Search} from "@mui/icons-material";
import {CrudFilters} from "@pankod/refine-core";

export type FilterStates = {
	jmeno: string;
}

export const FilterStatesInit: FilterStates = {
	jmeno: ''
}

export const ListFilter: React.FC<{syncedLocationFilters: CrudFilters, onFilterChange: (Filters: CrudFilters) => void}> = (
	{onFilterChange, syncedLocationFilters}
) => {

	const FilterStatesValues: FilterStates = {...FilterStatesInit};

	syncedLocationFilters.map((value: any, index) => {
		const propName: keyof FilterStates = value.field.substring(8, value.field.length - 1);
		return FilterStatesValues[propName] = value.value;
	});

	const [FilterStates, setFilterStates] = useState<FilterStates>(FilterStatesValues);

	const parseFilterValues = (FormFilterValues: FilterStates) : CrudFilters => {
		let TypedFilters: CrudFilters = [];

		for (let [name, value] of Object.entries(FormFilterValues)) {
			TypedFilters.push({
				field: "Filters[" + name + "]",
				operator: "eq",
				value: (typeof value === 'boolean') ? (value ? '1' : '0') : value ,
			});
		}

		return TypedFilters;
	}

	const handleOnFormChange = (event: any) => {

		const target = event.target;
		const name = target.name;
		let value;

		if (name.length > 0) {

			switch (target.type) {
				case 'checkbox':
					value = target.checked;
					break;
				case 'date':
					value = target.value;
					break;
				default:
					value = target.value;
					break;
			}

			if (event.target.name === 'submit') {

				const UpdatedStates = {
					...FilterStates
				};

				setFilterStates(UpdatedStates);

				const TypedFilters = parseFilterValues(UpdatedStates);

				onFilterChange(TypedFilters);
			} else {
				const UpdatedStates = {
					...FilterStates,
					[name]: value
				};

				setFilterStates(UpdatedStates);
			}
		}
		if (event.type === 'submit') {
			const TypedFilters = parseFilterValues(FilterStates);
			onFilterChange(TypedFilters);
		}

		event.preventDefault();
	}

	const handleOnFormResetClick = (event: MouseEvent<HTMLButtonElement>) => {
		setFilterStates(FilterStatesInit);
		onFilterChange(parseFilterValues(FilterStatesInit));
	}

	return (
		<Stack gap={0} marginBottom={4}>
			{/*<Typography variant={'overline'}>Parametry pro filtrovaní tabulky:</Typography>*/}
			<form className="form-wrapper" onChange={handleOnFormChange} onSubmit={handleOnFormChange}>
				<Grid
					container
					rowSpacing={2}
					columnSpacing={2}
				>
					{/*<Grid item xs={2}>
						<FormControl variant={'standard'} fullWidth size="small">
							<InputLabel shrink>Typ záznamu</InputLabel>
							<Select name={'typ'} onChange={this.handleOnFormChange} value={this.state.typ} displayEmpty={true}>
								<MenuItem value={''}>---</MenuItem>
								<MenuItem value={'ORIGINAL'}>Originál</MenuItem>
								<MenuItem value={'KOPIE'}>Kopie</MenuItem>
							</Select>
						</FormControl>
					</Grid>*/}
					<Grid item xs={10}>
						<FormControl variant={'standard'} fullWidth size="small">
							<InputLabel shrink>Klient / Partner</InputLabel>
							<Input autoFocus name={'jmeno'} value={FilterStates.jmeno} />
						</FormControl>
					</Grid>
					{/*<Grid item xs={1} textAlign={'center'}>
						<InputLabel>Aktivní</InputLabel>
						<Checkbox name='jeAktivni' checked={this.state.jeAktivni}/>
					</Grid>*/}
					{/*<Grid item xs={2}>
						<DatePicker
							label="Datum začátek"
							value={this.state.casZacatek}
							inputFormat={'dd.MM.yyyy'}
							onChange={(value, keyboardInputValue) => {
								this.handleOnFormChange({
									target: {
										name: 'casZacatek',
										type: 'date',
										value: value as Date
									}
								})
							}}
							renderInput={(params) => (
								<TextField {...params} InputLabelProps={{shrink: true}} size={"small"} variant={"standard"} />
							)}
						/>
					</Grid>
					<Grid item xs={2}>
						<DatePicker
							label="Datum konec"
							value={this.state.casKonec}
							inputFormat={'dd.MM.yyyy'}
							onChange={(value, keyboardInputValue) => {
								this.handleOnFormChange({
									target: {
										name: 'casKonec',
										type: 'date',
										value: value as Date
									}
								})
							}}
							renderInput={(params) => (
								<TextField {...params} InputLabelProps={{shrink: true}} size={"small"} variant={"standard"} />
							)}
						/>
					</Grid>*/}
					<Grid item xs={2} textAlign={'right'}>
						<Button
							name={'submit'}
							sx={{marginTop: '16px'}}
							variant={"outlined"}
							size={'small'}
							color="info"
							onClick={handleOnFormChange}
							startIcon={<Search/>}
						>
							Vyhledat
						</Button>
						<Button
							sx={{
								marginTop: '16px',
								marginLeft: '8px',
							}}
							variant={"outlined"}
							size={'small'}
							color={'inherit'}
							onClick={handleOnFormResetClick}
							startIcon={<Clear/>}
						>
							Vše
						</Button>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
}
