import * as React from "react";

import { useForm } from "react-hook-form";

import {
    Button,
    TextField,
    Box,
    Typography,
    Container,
    Card,
    CardContent,
} from "@mui/material";

import {useCustom, useNavigation, useNotification, useRouterContext} from "@pankod/refine-core";

import config from "../../config/config.local";
import {FormNotifications} from "../../helpers/formNotifications";
import {useState} from "react";
import axios from "axios";

type IUpdatePasswordForm = {
    password: string;
    passwordRepeat: string;
};

export const UpdatePasswordPage: React.FC = () => {

    const {useParams} = useRouterContext();

    const Navigation = useNavigation();

    const {open, close} = useNotification();

    const routerParams = useParams<{resource: string, token: string}>();

    const [isFormEnabled, setIsFormEnabled] = useState(false);

    const [formFields, setFormFields] = useState<IUpdatePasswordForm>();

    const { data, isLoading, isSuccess } = useCustom({
        url: config.APP_API_URL + '/uzivatel/password-token/' + routerParams.token,
        method: 'get',
        successNotification: (data: any) => ({
            type: 'success',
            message: FormNotifications.successMessage(data)
        }),
        errorNotification: (data: any) => ({
            type: 'error',
            message: FormNotifications.errorMessage(data)
        })
    });

    React.useEffect(() => {
        if (!isLoading && isSuccess) {
            if (data) {
                const platnostTokenu = data.data.platnost; // muze se pouzit treba na countdown
                setIsFormEnabled(true);
            }
        }
    }, [isLoading, isSuccess, data]);

    React.useEffect(() => {
        if (formFields) {
            if (formFields.password !== formFields.passwordRepeat) {
                open?.({
                    type: 'error',
                    message: 'Zadané hesla nesouhlasí !'
                });
            } else {
                setIsFormEnabled(false);
                axios.post(config.APP_API_URL + '/uzivatel/password-update/' + routerParams.token, formFields)
                    .then((data) => {
                        open?.({
                            type: 'success',
                            message: FormNotifications.successMessage(data)
                        });
                        Navigation.push('/logout');
                    })
                    .catch((error) => {
                        open?.({
                            type: 'error',
                            message: FormNotifications.errorMessage(error)
                        });
                        setIsFormEnabled(true);
                    });
            }
        }
    }, [formFields, Navigation, open, routerParams]);

    const { register, handleSubmit } = useForm<IUpdatePasswordForm>();
    const onSumbit = (data: IUpdatePasswordForm) => {
        setFormFields(data);
    };

    return (
        <>
            <Box component="div"
                sx={{
                    background: `radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #CCCCCC 100%)`,
                    backgroundSize: "cover",
                }}
            >
                <Container
                    component="main"
                    maxWidth="xs"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100vh",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <img src={config.APP_BASENAME + "/logo.svg"} alt="Naše3 Logo" width="320" />
                        </div>
                        <Box mt={4}>
                            <Card>
                                <CardContent sx={{ paddingX: "32px" }}>
                                    <Typography
                                        component="h1"
                                        variant="h5"
                                        align="center"
                                        sx={{
                                            fontWeight: "700",
                                            margin: "12px 0",
                                        }}
                                    >
                                        Nastavení nového hesla
                                    </Typography>
                                    <Typography component={'div'} align={'center'} hidden={!isLoading}>
                                        Ověřuji token uživatele ...
                                    </Typography>
                                    <Box
                                        component="form"
                                        onSubmit={handleSubmit(onSumbit)}
                                        gap="16px"
                                    >
                                        <TextField
                                            {...register("password", {
                                                required: true,
                                            })}
                                            id="password"
                                            margin="normal"
                                            size="small"
                                            fullWidth
                                            label={'Nové heslo'}
                                            name="password"
                                            type="password"
                                            autoFocus
                                            autoComplete={'new-password'}
                                            disabled={!isFormEnabled}
                                        />
                                        <TextField
                                            {...register("passwordRepeat", {
                                                required: true,
                                            })}
                                            id="passwordRepeat"
                                            margin="normal"
                                            size="small"
                                            fullWidth
                                            label={'Nové heslo (znovu)'}
                                            name="passwordRepeat"
                                            type="password"
                                            placeholder="●●●●●●●●"
                                            autoComplete={'new-password'}
                                            disabled={!isFormEnabled}
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{
                                                my: "8px",
                                                color: "white",
                                            }}
                                            disabled={!isFormEnabled}
                                        >
                                            Nastavit heslo
                                        </Button>

                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};
