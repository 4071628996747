import React, {useEffect, useState} from "react";
import {CrudFilter, CrudFilters, Pagination} from "@pankod/refine-core";
import { Button, csCZ, GridColumns, GridFilterModel, GridSortModel, Icon, ShowButton, Stack } from "@pankod/refine-mui";
import {INasetriEntity} from "../interfaces";
import {format} from "date-fns";
import {Man, Woman} from "@mui/icons-material";
import {DataGridPro, GridRowId, GridSortItem} from "@mui/x-data-grid-pro";
import {ListFilter} from "../pages/nasetri/listFilter";
import {useDataProvider} from "../App";
import config from "../config/config.local";

type NasetriGridProps = {
	jwtToken: string
}

export const NasetriGrid: React.FC<NasetriGridProps> = ({jwtToken}) => {

	const DataProvider = useDataProvider({
		type: "ext"
	});

	const columns = React.useMemo<GridColumns<INasetriEntity>>(
		() => [
			{
				field: "nasetriPk",
				headerName: "EČ",
				type: "number",
				width: 80,
				headerAlign: "left",
				align: "left",
				sortable: false,
			},
			{
				field: "casZmeny",
				headerName: "Poslední změna",
				width: 150,
				type: 'dateTime',
				valueFormatter: ((value) => {
					return value.value ? format(new Date(value.value), 'dd.MM.yyyy HH:mm') : "---";
				})
			},
			{
				field: "Klient",
				renderCell: function render({row}) {
					let PohlaviIcon = (row?.Klient ? row?.Klient.pohlavi === 'MUZ' ? <Man sx={{color: 'blue', opacity: 0.7}}/> : row?.Klient.pohlavi === 'ZENA' ? <Woman sx={{color: 'red', opacity: 0.7}}/> : '' : <></>);
					return row?.Klient ?
						<>{PohlaviIcon} {row?.Klient.prijmeni}, {row?.Klient.krestni} ({row?.Klient.rokNarozeni})</>:
						'---';
				},
				headerName: "Klient",
				type: 'string',
				width: 180,
				flex: 1,
				sortable: false,
			},
			{
				field: "Partner",
				renderCell: function render({row}) {
					let PohlaviIcon = (row?.Partner ? row?.Partner.pohlavi === 'MUZ' ? <Man sx={{color: 'blue', opacity: 0.7}}/> : row?.Partner.pohlavi === 'ZENA' ? <Woman sx={{color: 'red', opacity: 0.7}}/> : '' : <></>);
					return row?.Partner ?
						<>{PohlaviIcon} {row?.Partner.prijmeni}, {row?.Partner.krestni} ({row?.Partner.rokNarozeni})</>:
						'---';
				},
				headerName: "Partner",
				type: 'string',
				width: 180,
				flex: 1,
				sortable: false,
			},
			{
				field: "actions",
				type: "actions",
				headerName: "Akce",
				renderCell: function render({row}) {
					return (
						<Stack direction="row" spacing={1}>
							<Button
								startIcon={<Icon>login</Icon>}
								variant="text"
								target="_blank"
								href={config.APP_API_URL_EXT + '/auth/nasetri/' + row.nasetriPk + '?jwtToken=' + jwtToken}
							>
								Otevřít
							</Button>
						</Stack>
					);
				},
				align: "center",
				headerAlign: "center",
				width: 200,
			}
		],
		[]
	);

	const [isLoading, setIsLoading] = useState(false);

	const [Filters, setFilters] = useState(Array<CrudFilter>);

	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(25);

	type QueryOptionsPropsType = {
		sortModel?: any,
		filterModel?: any,
		pagination?: {current?: number, pageSize?: number}
	}

	const [data, setData] = useState(Array<INasetriEntity>);
	const [totalRows, setTotalRows] = useState(0);

	const [queryOptions, setQueryOptions] = React.useState<QueryOptionsPropsType>({});

	const queryOptionsProps = React.useCallback((options: QueryOptionsPropsType) => {
		let _sortProps = Array<{field: string, order: any}>();
		let _filterProps: CrudFilters = [];

		if (jwtToken) {
			_filterProps.push({
				field: 'jwtToken',
				operator: "eq",
				value: jwtToken
			})
		}

		options.sortModel?.map((item: GridSortItem) => {
			return _sortProps.push({field: item.field, order: item.sort})
		});

		if (options.filterModel) {
			for (const [key, value] of Object.entries(options.filterModel)) {
				const filterModelItem = value as CrudFilter;
				if (filterModelItem.value.length > 0)
					_filterProps.push(filterModelItem);
			}
		}

		const _paginationProps: Pagination = {
			pageSize: options.pagination?.pageSize || pageSize,
			current: options.pagination?.current || page,
		};

		return {
			sort: _sortProps,
			filters: _filterProps,
			pagination: _paginationProps
		};
	}, []);

	useEffect(() => {
		getData();
	}, [queryOptions]);

	async function getData()
	{
		setIsLoading(true);
		await DataProvider.getList({
				resource: 'nasetri',
				...queryOptionsProps(queryOptions),
			}).then((value) => {
				setTotalRows(value.total || 0);
				setData(value.data as any);
			}).finally(() => {
				setIsLoading(false);
			});
	}

	const onListFilterChange = React.useCallback((Filters: CrudFilters) => {
		setFilters(Filters);
		setQueryOptions({ filterModel: { ...Filters } });
	}, []);

	const onFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
		setQueryOptions({ filterModel: { ...filterModel } });
	}, []);

	const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
		setQueryOptions({ sortModel: [...sortModel] });
	}, []);

	const onPageChange = React.useCallback((newPage: number) => {
		setPage(newPage);
		setQueryOptions({ pagination: { current: newPage+1} });
	}, []);

	const onPageSizeChange = React.useCallback((newPageSize: number) => {
		setPageSize(newPageSize);
		setQueryOptions({ pagination: { pageSize: newPageSize} });
	}, []);

	return (<>
		<ListFilter onFilterChange={onListFilterChange} syncedLocationFilters={Filters} />

		<DataGridPro
			loading={isLoading}
			columns={columns}
			rows={data}
			autoHeight
			getRowId={(row) => row.nasetriPk}
			localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
			sortingMode="server"
			onSortModelChange={onSortModelChange}
			filterMode="server"
			onFilterModelChange={onFilterModelChange}
			disableColumnMenu={true}
			pagination={true}
			paginationMode={"server"}
			rowsLoadingMode={"server"}
			rowCount={totalRows}
			page={page}
			pageSize={pageSize}
			rowsPerPageOptions={[25, 50, 100]}
			onPageChange={(newPage) => onPageChange(newPage)}
			onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
			sx={{
				m: 0,
				fontSize: '12px',
			}}
		/>
	</>);
}
