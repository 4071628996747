import React from "react";
import {
    useGetIdentity,
    useLogout, useNavigation,
} from "@pankod/refine-core";
import {
    AppBar,
    Toolbar,
    Typography, IconButton, Menu, MenuItem, Stack, Link, width, Button,
} from "@pankod/refine-mui";
import {Logout, MenuRounded, VerifiedUser} from "@mui/icons-material";
import config from "../../config/config.local";

export const Header: React.FC = () => {

    const navigation = useNavigation();
    const {mutate: logout} = useLogout();
    const {data: user} = useGetIdentity();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogoClick = (event: React.MouseEvent<HTMLElement>) => {
        navigation.push("/nasetri");
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar color="default" position="sticky" elevation={1}>
            <Toolbar>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: '60%'
                    }}
                >
                    <Link onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
                        <img src={config.APP_BASENAME + "/logo.svg"} alt="Refine" width="140px"/>
                    </Link>
                </Stack>
                <Stack
                    direction="row"
                    gap="4px"
                    alignItems="center"
                    justifyContent="right"
                    sx={{
                        width: '40%'
                    }}
                >
                    <Typography variant={'subtitle2'}>
                        {user?.session?.krestni} {user?.session?.prijmeni}
                    </Typography>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
